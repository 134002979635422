import { defineComponent } from 'vue';
import '@fortawesome/fontawesome-free/css/all.css';
export default defineComponent({
    name: 'Timer',
    props: {
        value: {
            type: Number,
            default: null
        },
        stop: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            minutes: 0,
            seconds: 0,
            intervalId: null
        };
    },
    methods: {
        startTimer: function () {
            var _this = this;
            this.minutes = Math.floor(this.value / 60);
            this.seconds = this.value % 60;
            this.addZeroToNumber();
            this.intervalId = setInterval(function () {
                if (_this.seconds == 0) {
                    if (_this.minutes == 0) {
                        _this.$emit('set-timer-finished');
                        clearInterval(_this.intervalId);
                    }
                    else {
                        _this.minutes--;
                        _this.seconds = 59;
                    }
                }
                else {
                    _this.seconds--;
                }
                _this.addZeroToNumber();
            }, 1000);
        },
        addZeroToNumber: function () {
            this.minutes = this.minutes.toString().padStart(2, '0');
            this.seconds = this.seconds.toString().padStart(2, '0');
        },
        stopTimer: function () {
            clearInterval(this.intervalId);
        }
    },
    mounted: function () {
        this.startTimer();
    },
    watch: {
        stop: function (value) {
            if (value)
                this.stopTimer();
        }
    }
});
